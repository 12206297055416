const SlideUpBase = {
  start: {
    transform: `translateY(110%)`,
    opacity: 0,
  },
  update: {
    transform: `translateY(0%)`,
    opacity: 1,
    config: { mass: 1, tension: 560, friction: 55, clamp: true },
  },
  perWord: true,
  delayBetweenItems: 30,
};

export const SlideUp = {
  ...SlideUpBase,
  clipOverflow: false,
};

export const SlideUpClip = {
  ...SlideUpBase,
  clipOverflow: true,
};
export const SlideUpClipPerWord = {
  ...SlideUpBase,
  clipOverflow: true,
  perWord: false,
};

export const SlideUpHeavy = {
  start: {
    transform: `translateY(10em)`,
    opacity: 0,
  },
  update: {
    transform: `translateY(0em)`,
    opacity: 1,
    config: { mass: 10, tension: 560, friction: 100, clamp: true },
  },
  delayBetweenItems: 150,
};

export const SlideRightToLeftClip = {
  start: {
    transform: `translateX(5em)`,
    opacity: 0,
  },
  update: {
    transform: `translateX(0em)`,
    opacity: 1,
    config: { mass: 10, tension: 560, friction: 100, clamp: true },
  },
};

export const getVariantConfig = (variant) => {
  switch (variant) {
    case 'SlideUp':
      return SlideUp;
    case 'SlideUpClip':
      return SlideUpClip;
    case 'SlideUpClipPerWord':
      return SlideUpClipPerWord;
    case 'SlideUpHeavy':
      return SlideUpHeavy;
    case 'SlideRightToLeftClip':
      return SlideRightToLeftClip;

    default:
      return SlideUp;
  }
};
