import React, { cloneElement } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { animated as a, useSprings } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import styles from './animate.module.scss';
import { getVariantConfig } from './trail.config';

export const TrailAnimation = ({
  children,
  component,
  start,
  update,
  delayBeforeStart,
  delayBetweenItems,
  perWord,
  clipOverflow,
  reverse,
  visibleAt,
  variant,
  ...otherProps
}) => {
  const isString = typeof children === 'string';

  const config = getVariantConfig(variant);
  const s = start || config.start;
  const u = update || config.update;
  const d = delayBetweenItems || config.delayBetweenItems;
  const dStart = delayBeforeStart || config.delayBeforeStart;
  const clip = clipOverflow || config.clipOverflow;
  const pWord = perWord === undefined ? config.perWord : perWord;

  let renderAbles = [];
  if (isString) {
    if (!pWord) {
      renderAbles = children.split('');
    } else {
      const words = children.split(' ');
      words.forEach((word, i) => {
        renderAbles.push(word);
        if (i === words.length - 1) return;
        renderAbles.push(' ');
      });
    }
  } else {
    renderAbles = React.Children.toArray(children);
  }

  const [springs, setSprings] = useSprings(renderAbles.length, () => s);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: visibleAt / 100,
  });

  if (inView) {
    const fire = () =>
      setSprings((index) => ({
        ...u,
        delay: d * index,
      }));
    if (delayBeforeStart) {
      setTimeout(fire, dStart);
    } else {
      fire();
    }
  }

  const animatedWrapper = isString ? <a.span /> : <a.div />;
  const renderedOutput = renderAbles.map((l, idx) => {
    let i = idx;
    if (reverse) {
      i = renderAbles.length - 1 - idx;
    }
    const letterProps = {
      key: `${l}-${idx}`,
      className: styles.inherit,
      style: springs[i],
    };
    if (l === ' ')
      return (
        <a.span {...letterProps} className={clsx(styles.inherit, styles.space)}>
          &nbsp;&nbsp;
        </a.span>
      );

    const output = cloneElement(animatedWrapper, {
      ...letterProps,
      children: l,
    });

    const clipElement = isString ? <span /> : <div />;
    if (clip) {
      return cloneElement(clipElement, {
        key: `${l}-${idx}`,
        className: clsx(styles.inherit, styles.clip),
        children: output,
      });
    }
    return output;
  });

  let wrappedRenderedOutput = [];
  if (isString && !pWord) {
    const breakIndexes = [];
    const letters = children.split('');
    letters.forEach((child, i) => child === ' ' && breakIndexes.push(i + 1));
    breakIndexes.push(letters.length);
    breakIndexes.forEach((breakIndex, i) => {
      const lastIndex = i === 0 ? 0 : breakIndexes[i - 1];
      const word = renderedOutput.slice(lastIndex, breakIndex);
      wrappedRenderedOutput.push(
        <span className={styles.inherit} key={`word-${breakIndex}`}>
          {word}
        </span>
      );
    });
  }

  return cloneElement(component, {
    ...otherProps,
    ref: ref,
    children: isString && !pWord ? wrappedRenderedOutput : renderedOutput,
  });
};

TrailAnimation.defaultProps = {
  component: <div />,
  start: undefined,
  update: undefined,
  delayBeforeStart: 0,
  delayBetweenItems: undefined,
  perWord: undefined,
  clipOverflow: undefined,
  reverse: false,
  visibleAt: 0,
  variant: '',
};

TrailAnimation.propTypes = {
  children: PropTypes.node,
  component: PropTypes.element,
  delayBetweenItems: PropTypes.number,
  delayBeforeStart: PropTypes.number,
  start: PropTypes.any,
  update: PropTypes.any,
  perWord: PropTypes.bool,
  clipOverflow: PropTypes.bool,
  reverse: PropTypes.bool,
  visibleAt: PropTypes.number,
  variant: PropTypes.string,
};

export default TrailAnimation;
